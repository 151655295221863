@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lato Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato Black';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Black.ttf') format('truetype');
  }

  body {
    @apply antialiased;
    background-color: #fff;
    height: 100%;
  }

  .default-container {
    height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .underline {
    text-decoration: underline;
  }
}

.recurly-element-number,
.recurly-element-month,
.recurly-element-year,
.recurly-element-cvv {
  width: 100%;
  border: 0;
  margin-top: 0;
}

.recurly-element,
.recurly-hosted-field {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  height: 55px;
  font-family: 'Brandon Text Regular Medium';
  font-size: 12px;
  margin-top: 0;
  width: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}
